.navbar {
	width: 100%;
	height: 70px;
	background: linear-gradient(
    135deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 46%,
    rgba(252, 176, 69, 1) 90%
  );
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar h1 {
	color: white;
	font-size: 25px;
	margin-left: 20px;
}

.white_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 120px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	margin-right: 20px;
}
