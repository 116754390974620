.login_container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 46%,
    rgba(252, 176, 69, 1) 90%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_form_container {
  width: 900px;
  height: 500px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    137deg,
    rgba(131, 58, 180, 1) 16%,
    rgba(253, 29, 29, 1) 65%,
    rgba(252, 176, 69, 1) 100%
  );
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_container h1 {
  font-size: 40px;
  margin-top: 0;
}

.input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 14px;
}

.error_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 46%,
    rgba(252, 176, 69, 1) 90%
  );
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right h1 {
  margin-top: 0;
  color: white;
  font-size: 40px;
  align-self: center;
}

.white_btn,
.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.green_btn {
  background-color: #0000002a;
  color: white;
  margin: 10px;
}

@media (max-width: 750px) {
  .login_form_container {
    width: 80%;
    height: 550px;
    display: block !important;
  }
  .form_container h1 {
    font-size: 30px;
  }
  .input {
    width: 95%;
  }
  .left {
    height: 400px;
    border: none;
  }

  .right {
    background: transparent;
    height: 120px;
  }
  .error_msg {
    width: 95%;
  }
  .green_btn {
    width: 75%;
  }
}
@media (max-width: 560px) {
  .input {
    width: 85%;
  }
  .error_msg {
    width: 85%;
  }
  .form_container h1 {
    font-size: 30px;
  }
  .right h1 {
    font-size: 30px;
  }
}
@media (max-width: 410px) {
  .input {
    width: 85%;
  }
  .error_msg {
    width: 85%;
  }
  .form_container h1 {
    font-size: 27px;
  }
}
@media (max-width: 365px) {
  .input {
    width: 75%;
  }
  .error_msg {
    width: 75%;
  }
  .form_container h1 {
    font-size: 22px;
  }
  .right h1 {
    font-size: 25px;
  }
  /* .green_btn{
    width: 150px;
  } */
}
@media (max-width: 290px) {
  .input {
    width: 70%;
  }
  .error_msg {
    width: 70%;
  }
  .form_container h1 {
    font-size: 20px;
  }
}
@media (max-width: 270px) {
  .input {
    width: 60%;
  }
  .error_msg {
    width: 60%;
  }
  .form_container h1 {
    font-size: 17px;
  }
  .right h1 {
    font-size: 20px;
  }
  .white_btn {
    width: 130px;
  }
  .green_btn {
    width: 130px;
  }
}
